import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import AOS from "aos";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const StepProcess = () => {
  return (
    <section>
      <Container>
        <Row className="titlerow2">
          <div className="text-center centerrow">
            <h2 className="sectiontitle  new-color">Trading starts today</h2>
          </div>
        </Row>

        <Row className="Stepprocessrow">
          <Col lg={3} md={12} sm={12} className="stepprocesscol">
            <div className="step">
              <div className="iconstep">
                <img src="/images/register.png" width={80} alt="" />
              </div>
              <div className="steptext">
                <h4 className="new-color">Step 1: Register</h4>
                <p>Enter your email and choose a password to get started</p>
              </div>
            </div>
          </Col>
          <Col lg={3} md={12} sm={12} className="stepprocesscol">
            <div className="step">
              <div className="iconstep">
                <img src="/images/fund.png" width={80} alt="" />
              </div>
              <div className="steptext">
                <h4 className="new-color">Step 2: FUND</h4>
                <p>
                  Open your live trading account with <br /> GTB FX Group
                </p>
              </div>
            </div>
          </Col>
          <Col lg={3} md={12} sm={12} className="stepprocesscol">
            <div className="step">
              <div className="iconstep">
                <img src="/images/trading.png" width={80} alt="" />
              </div>
              <div className="steptext">
                <h4 className="new-color">Step 3: Start Trading </h4>
                <p>Verify your personal identification to start trading</p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="text-center" style={{ margin: "50px 0" }}>
            <Link
              to={process.env.REACT_APP_REGISTER_LINK}
              className="commanbtn"
              target="_blank"
            >
              {" "}
              Open Live Account
            </Link>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default StepProcess;
