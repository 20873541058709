import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import MainSlider from "../MainSlider";
import Slider from "react-slick";
import Section1About from "../Section1About";
import Services from "../Services";
import TradeNow from "../TradeNow";
import Advantages from "../Advantages";
import StepProcess from "../StepProcess";
import Pricelist from "../Pricelist";
import Dwonloadnow from "../Dwonloadnow";
import AOS from "aos";
import { useEffect, useState } from "react";
import "aos/dist/aos.css";
import Whyus from "../Whyus";
import OurProducts from "../OurProducts";
import { debounce } from "lodash";

const MT5 = {
  maintitle: "POWERFULL TRADING PALTEFORM METATRADER 5",
  img: "/images/mt5-platform-hero2.webp",
  sevicetitle: "The Next Generation trading  Platform MetaTrader5",
  text: "MetaTrader 5 is a web-based trading platform designed to help Forex and stock traders automate trading using trading robots, signals and fundamental analysis.MT5 gives you access to our extensive line up of asset classes — including forex, stocks, indices, metals.",
};
const AboutCompany = {
  maintitle: `ABOUT ${process.env.REACT_APP_APPNAME}`,
  img: "/images/pngegg (16) copy.png",
  sevicetitle: "YOU HAVE ALL YOUR BASES COVERED WITH US.",
  text: "Here at GTB FX Group , we provide one of the safest online trading platforms to our clients and partners. We believe in developing a sustainable workforce through our years-long experience, in-depth knowledge of the financial market, and the association of our trusted partners.",
};

const Home = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const [scrollToTop, setScrollToTop] = useState(false);

  const handleScrollToTop = debounce(() => {
    document.documentElement.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setScrollToTop(false);
  }, 100); // Adjust the debounce time as needed

  useEffect(() => {
    if (scrollToTop) {
      handleScrollToTop();
    }
  }, [scrollToTop]);
  return (
    <div>
      <MainSlider />
      <section>
        <Services
          maintitle={AboutCompany.maintitle}
          img={AboutCompany.img}
          sevicetitle={AboutCompany.sevicetitle}
          text={AboutCompany.text}
        />
      </section>
      <section>
        <OurProducts />
      </section>
      <section>
        <Container>
          <Row className="titlerow2">
            <div className="text-center centerrow">
              <h2 className="sectiontitle  new-color">
                TRADE WITH {process.env.REACT_APP_APPNAME}
              </h2>
            </div>
          </Row>
          <Row className="mt5row">
            <Col md={6} lg={6} sm={12}>
              <div className="mt5image">
                <img
                  src="/images/pngaaa.com-6514522.png"
                  alt="MT5 Image"
                  width={"100%"}
                />
              </div>
            </Col>
            <Col md={6} lg={6} sm={12}>
              <div className="mt5text">
                <div className="sevicestext">
                  <div className="maintextsecvice">
                    <h2>DOWNLOAD THE MT5 PLATFORM</h2>
                    <img
                      src="/images/gtbfx-logo.png"
                      
                      alt=""
                      className="mt5logoimg"
                    />
                    <p className="myp">
                      Select a download type as per your device operating system
                      and your account regulation:
                    </p>
                  </div>
                  <div className="mt5btn">
                    <Link to={"/"} target="_blank" className="dwnbtn">
                      <i
                        class="fa-brands fa-windows"
                        style={{ marginLeft: "0px !important" }}
                      ></i>
                    </Link>
                    <Link to={"/"} target="_blank" className="dwnbtn">
                      <i class="fa-brands fa-apple"></i>
                    </Link>
                    <Link to={"/"} target="_blank" className="dwnbtn">
                      <i class="fa-solid fa-mobile"></i>
                    </Link>
                    <Link to={"/"} target="_blank" className="dwnbtn">
                      <i class="fa-solid fa-globe"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <StepProcess />
      </section>
      <section>
        <TradeNow />
      </section>

      <section>
        <Whyus />
      </section>

      <section>
        <Pricelist />
      </section>
    </div>
  );
};

export default Home;
