import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {useState,useEffect} from 'react' 
import { debounce } from 'lodash'
import { useNavigate } from 'react-router-dom'

const OurProducts = () => {
    const [scrollToTop, setScrollToTop] = useState(false);
    const navigate = useNavigate();

    const handleScrollToTop = debounce(() => {
      document.documentElement.scrollTo({
        top: 0 ,
        behavior: 'smooth',
      });
      setScrollToTop(false);
    }, 100); // Adjust the debounce time as needed
  
    useEffect(() => {
      if (scrollToTop) {
        handleScrollToTop();
      }
    }, [scrollToTop]);
    function forexclick()
    {
     
        window.scrollTo(0, 0);
    }
  return (
    <>
    <section>
        <Container>
        <Row className='titlerow2'>
            <div className="text-center centerrow">
                <h2 className="sectiontitle  new-color">our products</h2>
            </div>

        </Row>
        <Row>
            <Col lg={3} md={6} sm={12} className='ourproductnewcol'>
                <Link  to={'/forex'} onClick={forexclick} >
               
                <div className="ourproductbox">
                    <div className="productboxnew">
                    <div className="itemboxproduct">
                        <img src="/images/trading-icon-01.png" width={80} alt="" />
                    </div>
                    <div className='itemboxprodducttext text-center'>
                        <h4 className='new-color'> FOREX</h4>

                    </div>
                    </div>
                </div>
                </Link>
            </Col>
            <Col lg={3} md={6} sm={12} className='ourproductnewcol'>
            <Link to={'/metals'} onClick={forexclick} >

                <div className="ourproductbox">
                    <div className="productboxnew">
                    <div className="itemboxproduct">
                        <img src="/images/trading-icon-02.png" width={80} alt="" />
                    </div>
                    <div className='itemboxprodducttext text-center'>
                        <h4 className='new-color'> METALS</h4>

                    </div>
                    </div>
                </div>
                </Link>
            </Col>
            <Col lg={3} md={6} sm={12} className='ourproductnewcol' >
            <Link to={'/Cryptocurrency'} onClick={forexclick}>

                <div className="ourproductbox">
                    <div className="productboxnew">
                    <div className="itemboxproduct">
                        <img src="/images/trading-icon-03.png" width={80} alt="" />
                    </div>
                    <div className='itemboxprodducttext text-center'>
                        <h4 className='new-color'> CRYPTO</h4>

                    </div>
                    </div>
                </div>
                </Link>
            </Col>
            <Col lg={3} md={6} sm={12} className='ourproductnewcol'>
            <Link to={'/indices'} onClick={forexclick} >
                
                <div className="ourproductbox">
                    <div className="productboxnew">
                    <div className="itemboxproduct">
                        <img src="/images/trading-icon-04.png" width={80} alt="" />
                    </div>
                    <div className='itemboxprodducttext text-center'>
                        <h4 className='new-color'> INDICES</h4>

                    </div>
                    </div>
                </div>
                </Link>
            </Col>
        
        </Row>
        </Container>
    </section>

    </>
  )
}

export default OurProducts