import React from 'react'
import Pagebanner from '../Pagebanner'
import {  Container, Row ,Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import { useEffect } from 'react'
import 'aos/dist/aos.css';
const pagebanner={
    title:"Contact Us",
    name:"Contact Us",
    pagelink:"contactus"
  }

const ContanctUs = () => {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
    <div>
        <Pagebanner title={pagebanner.title} text={pagebanner.text} pagename={pagebanner.name}  pagelink={pagebanner.pagelink}/>
        <section>
            <Container>
                <Row className='conactrow'>
                    <Col lg={3} md={6} sm={12} className='contactcol' >
                        <div className="contactbox">
                            <div className="iconcintact">
                                <img src="/images/hpk-contact.png" alt=""  />
                            </div>
                            <div className='contactboxtext'>
                                <h5 className='new-color'>Call on</h5>
                                <p>{process.env.REACT_APP_MOBILENO}</p>

                            </div>
                            
                        </div>

                    </Col>
                    <Col lg={3} md={6} sm={12} className='contactcol' >
                        <div className="contactbox">
                            <div className="iconcintact">
                                <img src="/images/hpk-email.png" alt=""  />
                            </div>
                            <div className='contactboxtext'>
                                <h5 className='new-color'>Email On</h5>
                                <p>{process.env.REACT_APP_EMAIL}</p>

                            </div>
                            
                        </div>

                    </Col>
                    
                    <Col lg={3} md={6} sm={12} className='contactcol' >
                        <div className="contactbox">
                            <div className="iconcintact">
                                <img src="/images/hpk-support.png" alt=""  />
                            </div>
                            <div className='contactboxtext'>
                                <h5 className='new-color'>Support</h5>
                                <p>24/7</p>

                            </div>
                            
                        </div>

                    </Col>
                </Row>
                <Row className='contact-padding'>
                <Col lg={6} md={6} sm={12} className='contactcol' >
                        <div className="contactbox">
                            <div className="iconcintact">
                                <img src="/images/hpk-map.png" alt=""  />
                            </div>
                            <div className='contactboxtext'>
                                <h5 className='new-color'>Registered Address</h5>
                                <p>{process.env.REACT_APP_REGISTER_ADDRESS}</p>

                            </div>
                            
                        </div>

                    </Col>
                    <Col lg={6} md={6} sm={12} className='contactcol' >
                        <div className="contactbox">
                            <div className="iconcintact">
                                <img src="/images/hpk-map.png" alt=""  />
                            </div>
                            <div className='contactboxtext'>
                                <h5 className='new-color'>Physical Address</h5>
                                <p>{process.env.REACT_APP_PHYSICAL_ADDRESS}</p>

                            </div>
                            
                        </div>

                    </Col>
                </Row>
                {/* <Row className='titlerow2'>
            <div className="text-center centerrow">
                <h2 className="sectiontitle  new-color">Write to us</h2>
            </div>

        </Row>
        <Row className='conactformrow'>
            <Col className='text-center ' >
            <form action="" className='contactfrom'>
                <input type="text" name='name' placeholder='Enter Name' /> <br />
                <input type="email" name='email' placeholder='Enter Email' />  <br />
                <input type="number" name='mobileno' placeholder='Enter Mobile No.' /> <br />
                <textarea name="message" id=""  rows="" placeholder='Enter Your messages'></textarea><br />
                <div className='contactbtn'>

                <Link to={"/"} target="_blank" className='commanbtn2' style={{width:"92%"}}>SUBMIT</Link>
                </div>

            </form>
            </Col>
            
        </Row> */}
            </Container>
        </section>

    </div>
  )
}

export default ContanctUs