import React from 'react'
import { Link } from 'react-router-dom'
import { Container,Row,Col } from 'react-bootstrap'
import AOS from 'aos';
import { useEffect } from 'react'
import 'aos/dist/aos.css';

const Services = (props) => {
    
  return (
    <section>
        <Container>
        <Row className=''>
            <div className="text-center centerrow">
                <h2 className="sectiontitle  new-color">{props.maintitle}</h2>
            </div>

        </Row>
            <Row className='servicesrow' >
                <Col lg={6} md={6} sm={12} className='servicescolimg'>
                    <div className="seviceimg">
                        <img src={props.img} alt="" width={'100%'} />
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} >
                    <div className="sevicestext">
                        <div className='maintextsecvice'>
                            <h2>{props.sevicetitle}</h2>
                            <p className='myp'>{props.text}</p>
                            
                        </div>
                        <div className='servicebtn'>

                <Link to={process.env.REACT_APP_REGISTER_LINK} target="_blank" className='commanbtn2'>Open Live Account</Link>
                        </div>

                    </div>

                </Col>

            </Row>
        </Container>
    </section>
  )
}

export default Services